import React, { useState } from "react";
import Logo from '../../assets/img/logo-bollore-logistics-white.png';

const PopinGlobal = ({ title, text }) => {
    const [display, setDisplay] = useState(true);

    const closePopin = () => {
        setDisplay(false)
        localStorage.setItem("popinDisplayed", true)
    }
    return (
        <>
            {
                display &&
                <div className="c-popin-global">
                    <div className="c-popin-global__container">
                        <button className="close-btn" onClick={() => closePopin()}></button>
                        {
                            title &&
                            <h2>{title}</h2>
                        }
                        {
                            text &&
                            <div className="c-popin-global__container-text" dangerouslySetInnerHTML={{ __html: text }}></div>
                        }
                        <img src={Logo} alt="bolloré logistics" loading="lazy"/>
                    </div>
                </div>
            }
        </>
    )
}
export default PopinGlobal;