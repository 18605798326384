import React, {useEffect, lazy, Suspense} from "react";
import {Route, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Loader} from "../loaders/loader";

// templates
const Template404 = lazy(() => import('../../Templates/Template404'));
const TemplateHome = lazy(() => import('../../Templates/TemplateHome'));
const TemplateLocalHomePage = lazy(() => import('../../Templates/TemplateLocalHomePage'));
const TemplateNews = lazy(() => import('../../Templates/TemplateNews'));
const TemplateSingleNews = lazy(() => import('../../Templates/TemplateSingleNews'));
const TemplateOffers = lazy(() => import('../../Templates/TemplateOffers'));
const TemplateSingleOffer = lazy(() => import('../../Templates/TemplateSingleOffer'));
const TemplateSingleIndustry = lazy(() => import('../../Templates/TemplateSingleIndustry'));
const TemplateSingleService = lazy(() => import('../../Templates/TemplateSingleService'));
const TemplateContentPage = lazy(() => import('../../Templates/TemplateContentPage'));
const TemplateSiteMap = lazy(() => import('../../Templates/TemplateSiteMap'));

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
    'Template 404': Template404,
    'Template Home': TemplateHome,
    'Template Local Homepage': TemplateLocalHomePage,
    'Template News': TemplateNews,
    'Template Single News': TemplateSingleNews,
    'Template Offers': TemplateOffers,
    'Template Single Offer': TemplateSingleOffer,
    'Template Content Page': TemplateContentPage,
    'Template Single Industry': TemplateSingleIndustry,
    'Template Single Service': TemplateSingleService,
    'Template Sitemap': TemplateSiteMap,
};

/**
 * @component
 * @description crer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes
 */
function RouteForTemplates(route) {
    let lang = useSelector(state => state.global).lang;
    let history = useHistory();
    let search = useLocation().search; // si recherche type http://monurl.com/recherche?=texte
    let hash = useLocation().hash; // si recherche avec # dans l'url

    useEffect(
        () => {
            if (route.location.pathname === '/') {
                history.replace({pathname: '/' + lang});
            } else {
                history.replace({pathname: route['path_' + lang], search, hash});
            }
        }, [lang]);  // eslint-disable-line

    return (
        <Suspense fallback={<Loader/>}>
            <Route path={route['path_' + lang]} name={route.component} exact={route.exact} render={() =>
                React.createElement(DynamicTemplate[route.component], {
                    key: route._uid,
                    ...route.datas,
                    _uid: route._uid
                })
            }/>
        </Suspense>
    );
}

export default RouteForTemplates;