import React, {useState, useEffect, useRef} from "react";
import Autocomplete from 'react-autocomplete';
import { useSelector } from 'react-redux';

function matchStateToTerm(state, value) {
    return (
        state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        state.url.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
}

const Finder = ({local_homepages, translations}) => {

    const inputRef = useRef(null);
    const [autocomplete, setAutocomplete] = useState('');

    // breakpoints
    let breakpointStore = useSelector(state => state.breakpoint);
    let { mobile } = breakpointStore;

    // open / close selectBox
    const [selectOpen, setOpen] = useState(false);
    const toggleSelectBox = (e) => {
        selectOpen === false && mobile && setTimeout(() => window.scroll({top: window.innerHeight - 50, left: 0, behavior: 'smooth'}), 50);
        setOpen(!selectOpen);
        selectOpen === true && e.target.blur();
    };


    // select location
    // setLocation
    const [location, ] = useState({ 
        init: false,
        pays: '',
        url: '',
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: ''
    });

    useEffect(() => {
        if (selectOpen) {
            inputRef.current.focus();
        } else {
            location.userInput !== '' ? location.init = true : location.init = false;
        }
    }, [selectOpen, location]);

    const filteredSuggestions = local_homepages.items;

    return (
        local_homepages && (
            <div className="header_location" data-select-opened={`${!selectOpen ? false : true}`}>

                <div className="header_location-select" onClick={selectOpen === false ? toggleSelectBox : null}>
                    <span className="txt">{`${!location.init ? translations.find_a_location : location.pays }`}</span>
                    <div className="input">
                        <Autocomplete
                            ref={inputRef}
                            shouldItemRender={matchStateToTerm}
                            getItemValue={(item) => item.name}
                            items={filteredSuggestions}
                            inputProps={{placeholder: translations.write_something}}
                            renderMenu={children =>
                                <div className="header_location-list">
                                    <div className="header_location-listInner">
                                        {children}
                                    </div>
                                </div>
                            }
                            renderItem={(item, isHighlighted) =>
                                <a className="header_location-item hidden"
                                      style={{fontWeight: isHighlighted ? '700' : ''}}
                                      href={item.url} key={item._uid}>{item.name}</a>
                            }
                            value={autocomplete}
                            open={true}
                            // onMenuVisibilityChange={isOpen => setOpen(isOpen)}
                            onSelect={value => setAutocomplete('')}
                            onChange={e => setAutocomplete(e.target.value)}
                        />
                    </div>
                    <button className={`${!location.init ? (!selectOpen ? 'icon-down' : 'icon-up') : 'icon-close' }`} onClick={toggleSelectBox}></button>
                </div>
            </div>
        )
    )
}

export default Finder;