import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "scroll-behavior-polyfill";

// Redux
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { globalReducer } from './Store/Reducers/globalReducer';
import { pageReducer } from './Store/Reducers/pageReducer';
import { breakpointReducer } from './Store/Reducers/breakpointReducer';
import { podcastReducer } from './Store/Reducers/podcastReducer';

const reducer = combineReducers({
  global: globalReducer,
  page: pageReducer,
  breakpoint: breakpointReducer,
  podcast: podcastReducer
})

const store = createStore(reducer);

const root = createRoot(document.getElementById('root'));
root.render( <Provider store={store}>
  <App />
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
