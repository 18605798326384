const initialState = {
	currentTpl: 'homepage',
	pageTitle: null
}

/**
 * PAGE NAME REDUCER
 * @param {*} state 
 * @param {*} action 
 */
export function pageReducer( state = initialState, action ){
	switch(action.type){
		case 'UPDATE_PAGE':
			return { ...state, page: action.value }
		case 'UPDATE_PAGE_TITLE':
			return { ...state, pageTitle: action.value, }
		default:
			return state
	}
}