import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";

const Menu = ({ items, closeMenu }) => {

    const [open, setOpen] = useState();
    let submenuOpen = document.querySelector('.header_nav-item.is-open');
    useEffect(() => {
        if (submenuOpen) {
            submenuOpen.classList.remove('is-open');
        }
    });

    const [menuIdOpen, setMenuIdOpen] = useState(-1);

    // fix resize issue on safari mobile
    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return (
        <nav className="header_nav" role="navigation">
            <ul className="header_nav-list">
                {items.map((item) =>
                    <MenuItem
                        key={item.id}
                        {...item}
                        closeMenu={closeMenu}
                        active={item.id === open}
                        onClick={() => setOpen(item.id)}
                        menuOpen={item.id === menuIdOpen}
                        setMenuOpen={setMenuIdOpen}
                    />
                )}
            </ul>
        </nav>
    )
}

export default Menu;

const MenuItem = ({ id, children, virtual_page, title, url, closeMenu, active, onClick, menuOpen, setMenuOpen }) => {
    //const [subMenuOpen, setSubMenuOpen] = useState(false);
    return (
        <li className={`header_nav-item ${children.length > 0 ? 'hasChildren' : ''} ${menuOpen ? 'is-open' : ''}`}
            key={id}
            data-sub-opened={active ? true : false}
            onClick={onClick}
            onMouseEnter={() => setMenuOpen(id)}
            onMouseLeave={() => setMenuOpen(-1)}
        >
            {virtual_page === true ? (
                <button
                    onFocus={() => setMenuOpen(id)}
                    className="header_nav-link">{title}</button>
            ) : (
                <NavLink
                    onFocus={() => setMenuOpen(id)}
                    activeClassName="selected" className="header_nav-link" to={url} onClick={closeMenu}>{title}</NavLink>
            )}

            {/* sub nav for mobile devices */}
            {children.length > 0 && (
                <SubMenu children={children} closeMenu={closeMenu} />
            )}
        </li>
    )
}

const SubMenu = ({ children, closeMenu }) => {
    return (
        <div className="header_sub">
            <div className="layout">
                <ul className="header_sub-menu">
                    {children && children.map(({ id, url, title }) => <li className="header_sub-item" key={id} onClick={closeMenu}>
                        <NavLink className="header_sub-link" activeClassName="selected" to={url}>
                            {title}
                        </NavLink>
                    </li>)}
                </ul>
            </div>
        </div>
    );
};