import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import Axios from 'axios';

const Sharers = () => {
	
	const [isoCode, setIsoCode] = useState('');

	let dataGlobal = useSelector(state => state.global).global;
	let { networks, share_on_label } = dataGlobal.sharers;

	useEffect(() => {
        const fetchAPi = async () => {
            try {
                const fetch = await Axios.post(
                    // TEST CN
                    // `${process.env.REACT_APP_APP}/check-location.php?ip=1.119.255.255`
                    // TEST FR
                    // `${process.env.REACT_APP_APP}/check-location.php?ip=194.3.206.35`
                    // DEFAULT
                    `${process.env.REACT_APP_APP}/check-location.php`
                );
                //console.log(fetch.data.isoCode);
                setIsoCode(fetch.data.isoCode);
            } catch (err) {
                //console.err(err)
            }
        };
        fetchAPi();
	}, [isoCode]);
	
	return(
		<>

		{networks &&
			<div className="sharer">
				<div className="layout">
					<div className="sharer_wrapper">
						{share_on_label && <h3 className="sharer_text">{ share_on_label }</h3>}
							{isoCode === 'CN' ?
								<ul className="sharer_list">
									{networks.linkedin && <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} className="sharer_social icon icon-linkedin-plain" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Linkedin (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Linkedin'}`}</span>
									</a></li>}
									{networks.tencent && <li><a href={`http://v.t.qq.com/share/share.php?url=${window.location.href}`} className="sharer_social icon icon-tencent" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Tencent (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Tencent'}`}</span>
									</a></li>}
									{networks.weibo && <li><a href={`http://service.weibo.com/share/share.php?url=${window.location.href}`} className="sharer_social icon icon-weibo" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Weiboo (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Weibo'}`}</span>
									</a></li>}
								</ul>
							: 
								<ul className="sharer_list">
									{networks.linkedin && <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} className="sharer_social icon icon-linkedin-plain" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Linkedin (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Linkedin'}`}</span>
									</a></li>}
									{networks.twitter && <li><a href={`https://twitter.com/share?url=${window.location.href}`} className="sharer_social icon icon-twitter-plain" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Twitter (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Twitter'}`}</span>
									</a></li>}
									{networks.facebook && <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} className="sharer_social icon icon-facebook-plain" rel="noopener noreferrer" target="_blank" title={ `${share_on_label} Facebook (${dataGlobal?.translate?.newwindow})`}>
										<span className="sr-only">{ `${share_on_label + ' Facebook'}`}</span>
									</a></li>}
								</ul>
							}
					</div>
				</div>
			</div>
		}

		</>
	)
}
export default Sharers;