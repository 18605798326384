import "./../src/Styles/App.scss";
import React, { useEffect } from "react";
import ScrollToTop from "./Components/ScrollToTop";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import RouteForTemplates from "./Components/routes/route-for-templates";
import { LoaderPage } from "./Components/loaders/loader";
import { updateELOQUA, updateGlobalIdOlark } from "./Store/action";

// HOOKS
import useApi from "./Hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import {
    updateGlobalStoreGlobal,
    updateGlobalisLoaded,
    updateGlobalStoreRoutes,
    updateGlobalScrollLock,
    updateGlobalStoreLang,
    updateIsMobile,
    updateIsTablet,
    updateIsTabletWide,
    updateIsDesktop,
} from "./Store/action";

// TPL PARTS
import Header from "./Components/header/header";
import Footer from "./Components/footer/footer";
import SkipLink from "./Components/header/skiplink";
import PopinGlobal from "./Components/popin/popin-global";

const App = () => {
    // Global apis
    let dispatch = useDispatch();

    let [dataGlobalAPI, dataGlobalIsLoaded] = useApi({ name: "global" });
    let [dataRoutesAPI, routesIsLoaded] = useApi({ name: "routes" });

    let { routes, isLoaded } = useSelector((state) => state.global);
    let { mobile, tablet, tabletWide, desktop } = useSelector(
        (state) => state.breakpoint
    );
    let { page } = useSelector((state) => state.page);

    let dataRoutes = routes;

    // LISTENER TO OPEN COOKIE PANEL
    useEffect(() => {
        document.addEventListener("click", (evt) => {
            if (evt.target.closest(".open-cookies")) {
                window.tarteaucitron.userInterface.openPanel();
            }
        });
    });

    // ELOQUA
    useEffect(() => {
        if (window.tarteaucitron) {
            let timer = setInterval(() => {
                if (window.tarteaucitron.state.eloqua === true) {
                    dispatch(updateELOQUA(true));
                    clearInterval(timer)
                }
            }, 250);
        }
    }, []); // eslint-disable-line

    // INIT SCROLL LOCK REDUCER
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, [dispatch]);

    // STORE ADD GLOBAL API
    useEffect(() => {
        /* TODO : A RETIRER Une fois les translate fait */
        if (!dataGlobalAPI.translate) {
            dataGlobalAPI.translate = {
                "followus": "Suivez nous sur",
                "backhome": "Retour à la page d'accueil",
                "newwindow": "Ouverture dans une nouvelle fenêtre",
                "gobolloretransport": "Aller sur le site Bollore Transport et Logistics",
                "gocontenu": "Aller au contenu",
                "gomenu": "Aller au menu",
                "gonext": "Lire la suite"
            }
        }
        dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
    }, [dispatch, dataGlobalAPI]);

    // STORE ADD ROUTES API
    useEffect(() => {
        dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
    }, [dispatch, dataRoutesAPI]);

    // STORE SET IS LOADED
    useEffect(() => {
        dispatch(updateGlobalisLoaded(routesIsLoaded && dataGlobalIsLoaded));
    }, [dispatch, routesIsLoaded, dataGlobalIsLoaded]);

    // DISPATCH LANGUAGE FROM URL
    useEffect(() => {
        let langArray = window.location.href.split("/");
        langArray[3] === "en" && dispatch(updateGlobalStoreLang("en"));
        langArray[3] === "fr" && dispatch(updateGlobalStoreLang("fr"));
    }, [dispatch]);

    //DISPATCH ID OLARK
    useEffect(() => {
        if (window.tarteaucitron) {
            if (window.tarteaucitron.state["id_olark"] === true) {
                dispatch(updateGlobalIdOlark(true));
            } else {
                dispatch(updateGlobalIdOlark(true));
            }
        }
    }, [window.tarteaucitron.state["id_olark"]]); // eslint-disable-line

    // DISPATCH WINDOW SIZE ON RESIZE
    useEffect(() => {
        const checkDevice = () => {
            if (window.matchMedia("(max-width:767px)").matches && !mobile) {
                dispatch(updateIsMobile(true));
                dispatch(updateIsTablet(false));
                dispatch(updateIsTabletWide(false));
                dispatch(updateIsDesktop(false));
            }
            if (
                window.matchMedia("(min-width:768px) and (max-width:1023px)").matches &&
                !tablet
            ) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsTablet(true));
                dispatch(updateIsTabletWide(false));
                dispatch(updateIsDesktop(false));
            }
            if (
                window.matchMedia("(min-width:1024px) and (max-width: 1099px)")
                    .matches &&
                !tabletWide
            ) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsTablet(false));
                dispatch(updateIsTabletWide(true));
                dispatch(updateIsDesktop(false));
            }
            if (window.matchMedia("(min-width:1100px)").matches && !desktop) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsTablet(false));
                dispatch(updateIsTabletWide(false));
                dispatch(updateIsDesktop(true));
            }
        };

        checkDevice();

        window.addEventListener("resize", checkDevice);

        // clean listener
        return () => {
            window.removeEventListener("resize", checkDevice);
        };
    }, [dispatch, desktop, tabletWide, mobile, tablet]);

    // INIT PIXEL LINKEDIN FOR X PAGES
    useEffect(() => {
        if (
            window.location.href.indexOf("en/b-lab") > -1 ||
            window.location.href.indexOf("en/faq") > -1 ||
            window.location.href.indexOf("en/csr") > -1
        ) {
            let _linkedin_partner_id = "3172684";
            window._linkedin_data_partner_ids =
                window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            (function (l) {
                if (!l) {
                    window.lintrk = function (a, b) {
                        window.lintrk.q.push([a, b]);
                    };
                    window.lintrk.q = [];
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
            })(window.lintrk);
        }
    }, []);

    // SCROLL TO ELEMENT WHEN CLICKING AN ANCHOR LINK
    const currentPath = window.location.href;
    const scrollToTarget = (id) => {
        const target = document.getElementById(id);
        target && target.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
    useEffect(() => {
        const anchorId = window.location.hash;
        if (anchorId) {
            setTimeout(() => scrollToTarget(anchorId), 2500);
        }
        return;
    }, [currentPath])

    // document.querySelectorAll("*").forEach(item => item.addEventListener("focus", () => {
    //     item.style.border = "solid 2px red";
    //     console.log("focus >");
    //     console.log(item);
    //     console.log("----------");
    // }))
    // document.querySelectorAll("*").forEach(item => item.addEventListener("blur", () => {
    //     item.style.border = "";
    //     console.log("blur >");
    //     console.log(item);
    //     console.log("----------");
    // }))

    return (
        <div className={`app ${page}`}>
            <LoaderPage load={isLoaded} />
            <Router>
                {isLoaded ? (
                    <ScrollToTop>
                        <SkipLink />
                        <Header />
                        {
                            dataGlobalAPI.popin && !localStorage.getItem("popinDisplayed") &&
                            <PopinGlobal {...dataGlobalAPI.popin} />
                        }
                        <Switch>
                            {dataRoutes &&
                                dataRoutes.map((route) => {
                                    return (
                                        <RouteForTemplates key={route._uid} {...route} datas={{}} />
                                    );
                                })}
                        </Switch>

                        <Footer data={dataGlobalAPI.footer} />
                    </ScrollToTop>
                ) : (
                        <div style={{ height: "100vh" }}></div>
                    )}
            </Router>
        </div>
    );
};

export default App;
