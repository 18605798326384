import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateGlobalScrollLock, updateGlobalStoreLang} from '../../Store/action';
import {Link} from 'react-router-dom';
import Menu from './menu';
import Finder from './finder';

const Header = () => {

    let dataGlobal = useSelector(state => state.global).global;
    let lang = useSelector(state => state.global).lang;
    let page = useSelector(state => state.page).page;

    // DESTRUCTURING
    const {logo, logo_white, links, find_location, language} = dataGlobal.header;

    // SCROLL LOCK REDUCER
    let dispatch = useDispatch();
    let scrollStore = useSelector(state => state.global).lockScroll;
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, [dispatch, dataGlobal]);
    
    // OPEN NAV
    const [navOpen, setOpen] = useState(false);

    // ÉTAT OUVERT / FERMÉ DU MENU
    const toggleMenu = () => {
        setOpen(!navOpen);
        dispatch(updateGlobalScrollLock(!scrollStore));
    };

    // Close menu when click on logo
    const closeMenu = () => {
        setOpen(false);
        dispatch(updateGlobalScrollLock(false));
    };

    return (
        <header id="menu" role="banner" className={`${!navOpen ? 'header' : 'header navOpened'}`}>
            <div className="layout header_wrapper">
                <div className="header_logo" onClick={ closeMenu }>
                    {
                        //TODO : TRANSLATE
                    }
                    <Link to={`/${lang}/`} title={dataGlobal?.translate?.backhome}>
                        {logo && (
                            <img className="header_logo-color" src={logo.img_src} alt={dataGlobal?.translate?.backhome ? dataGlobal?.translate?.backhome : ''}/>
                        )}

                        {logo_white && (
                            <img className="header_logo-white" src={logo_white.img_src} alt={dataGlobal?.translate?.backhome ? dataGlobal?.translate?.backhome : ''}/>
                        )}
                    </Link>
                </div>

                <Menu items={links} closeMenu={toggleMenu}/>

                <Finder {...find_location} />

                {page !== 'single-local-homepage' && <LanguageSwitch datas={language}/>}

                <ToggleMenu switchIcon={toggleMenu}/>
            </div>
        </header>
    )
}
export default Header;

/**
 * @component Changement de langue
 */
const LanguageSwitch = ({datas}) => {

    const langStore = useSelector(state => state.global).lang;
    const dispatch = useDispatch();

    return (
        <div className="header_lang">
            {datas.sort(item => item.lang === "fr" ? -1 : 1).map((item, i) => (
                <button key={i}
                     data-lang={item.lang}
                     data-lang-active={item.lang === langStore}
                     className="header_lang-btn"
                     onClick={() => dispatch(updateGlobalStoreLang(item.lang))}>
                    {item.text}
                </button>
            ))}
        </div>
    );
};

const ToggleMenu = ({switchIcon}) => {
    return (
        <div className="header_toggle" onClick={switchIcon}>
            <span className="trait top"></span>
            <span className="trait middle"></span>
            <span className="trait bottom"></span>
        </div>
    );
};