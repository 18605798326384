import React from 'react';
import { useSelector } from 'react-redux';

const SkipLink = () => {

    let dataGlobal = useSelector(state => state.global).global;

    return (
        <ul className="skiplink">
            <li><a href="#main">{dataGlobal?.translate?.gocontenu}</a></li>
            <li><a href="#menu">{dataGlobal?.translate?.gomenu}</a></li>
        </ul>
    )
}
export default SkipLink;