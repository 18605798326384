import { PODCAST } from "../action";

const initialState = {
	id: ''
}

/**
 * PODCAST REDUCER
 * @param {*} state 
 * @param {*} action 
 */
export function podcastReducer( state = initialState, action ){
	switch(action.type){
		case PODCAST:
			return { ...state, id: action.value }
		default:
			return state
	}
}