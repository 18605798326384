import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Wysiwyg from '../../modules/wysiwyg';
import Sharers from './sharers';

const Footer = () => {
    let dataGlobal = useSelector(state => state.global).global;
    let { contact_information, follow_us, newsletter, links, logo, informations } = dataGlobal.footer;

    let breakpointStore = useSelector(state => state.breakpoint);
    let { mobile, tablet, tabletWide, desktop } = breakpointStore;

    let height = 200;
    if (newsletter) {
        if (mobile) {
            height = newsletter.iframe.height.mobile;
        } else if (tablet || tabletWide) {
            height = newsletter.iframe.height.tablet;
        } else if (desktop) {
            height = newsletter.iframe.height.desktop;
        }
    }

    useEffect(() => {
        if (newsletter.iframe && window.tarteaucitron.state.googletagmanager === true) {
            window.dataLayer.push({
                event: 'iframeLoad',
                category: 'iframe',
                action: 'iframeLoad',
                label: 'footer newsletter iframeLoad',
                nonInteraction: true,
                transport: 'xhr'
            })
        }
    }, [newsletter.iframe])

    // tarteaucitron

    const [tacAllowed, setTacAllowed] = useState(false);

    useEffect(() => {
        setTacAllowed(window.tarteaucitron.state.eloquaMarketing === true);
        const listenner = () => setTacAllowed(true)
        window.addEventListener("tac.eloquaMarketing.allowed", listenner)
        return (() => {
            window.removeEventListener("tac.eloquaMarketing.allowed", listenner)
        })
    }, [])

    const renderTacEngagement = useCallback(() => {
        try {
            return window.tarteaucitron.engage("eloquaMarketing")
        } catch (err) {
            return (null)
        }
    }, [])

    return (
        <>

            <Sharers />

            <footer className="footer" role="contentinfo">
                <div className="footer_top">
                    {
                        informations?.text && <div className="footer_mentions layout" dangerouslySetInnerHTML={{__html : informations.text}}></div>
                    }
                    <div className="footer_wrapper layout">
                        {contact_information && (
                            <div className="footer_office">
                                {contact_information.title && (
                                    <h4 className="footer_office-title">{contact_information.title}</h4>
                                )}

                                {contact_information.address && (
                                    <address className="footer_office-address">
                                        <span className="icon-address"></span>
                                        <Wysiwyg>{contact_information.address}</Wysiwyg>
                                    </address>
                                )}

                                {contact_information.phone && (
                                    <p className="footer_office-phone">
                                        <span className="icon-phone"></span>
                                        {contact_information.phone}
                                    </p>
                                )}
                            </div>
                        )}


                        {follow_us && (
                            <div className="footer_social">
                                {follow_us.title && (
                                    <h4 className="footer_social-title">{follow_us.title}</h4>
                                )}
                                <ul>
                                    {follow_us.linkedin && (
                                        <li><a className="footer_social-app linkedin" href={follow_us.linkedin.url}
                                            title={`${dataGlobal?.translate?.followus} Linkedin (${dataGlobal?.translate?.newwindow})`}
                                            rel="noopener noreferrer" target="_blank">
                                            <span className="icon-linkedin"></span>
                                            {follow_us.linkedin.name}
                                        </a></li>
                                    )}
                                    {follow_us.twitter && (
                                        <li><a className="footer_social-app twitter" href={follow_us.twitter.url}
                                            title={`${dataGlobal?.translate?.followus} Twitter (${dataGlobal?.translate?.newwindow})`}
                                            rel="noopener noreferrer" target="_blank">
                                            <span className="icon-twitter"></span>
                                            {follow_us.twitter.name}
                                        </a></li>
                                    )}
                                    {follow_us.youtube && (
                                        <li><a className="footer_social-app youtube" href={follow_us.youtube.url}
                                            title={`${dataGlobal?.translate?.followus} Youtube (${dataGlobal?.translate?.newwindow})`}
                                            rel="noopener noreferrer" target="_blank">
                                            <span className="icon-youtube"></span>
                                            {follow_us.youtube.name}
                                        </a></li>
                                    )}
                                    {follow_us.instagram && (
                                        <li><a className="footer_social-app instagram" href={follow_us.instagram.url}
                                            title={`${dataGlobal?.translate?.followus} Instagram (${dataGlobal?.translate?.newwindow})`}
                                            rel="noopener noreferrer" target="_blank">
                                            <span className="icon-instagram"></span>
                                            {follow_us.instagram.name}
                                        </a></li>
                                    )}
                                </ul>
                            </div>
                        )}

                        {newsletter && (
                            <div className="footer_nl">
                                {newsletter.title && (
                                    <>
                                        <h4 className="footer_nl-title">{newsletter.title}</h4>
                                        <div className="footer_wrapper">
                                            <Wysiwyg className="footer_nl-text">{newsletter.text}</Wysiwyg>
                                            <RenderIframe newsletter={newsletter} tacAllowed={tacAllowed} renderTacEngagement={renderTacEngagement} height={height} />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="footer_bottom">
                    {links && (
                        <div className="footer_wrapper layout">
                            <div className="footer_links">
                                {links.map(({ id, url, title, classes }) => {
                                    const className = (classes || []).join(" ");
                                    return <div className="footer_links-item" key={id}>
                                        <Link className={className} to={url}>{title}</Link>
                                    </div>
                                })}
                            </div>
                            {(logo && logo.url && logo.img_src) && (
                                <div className="footer_siteBollore">
                                    <a href={logo.url} rel="noopener noreferrer" target="_blank" title={dataGlobal?.translate?.gobolloretransport + " (" + dataGlobal?.translate?.newwindow + ")"}>
                                        <p>A brand of</p>
                                        <img src={logo.img_src} alt="Bollore Transport & Logistics" loading="lazy"/>
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </footer>

        </>
    )
}
export default Footer;

const RenderIframe = ({ newsletter, tacAllowed, renderTacEngagement, height }) => {

    if (!newsletter.iframe) return (null)
    if (!newsletter.iframe.url) return null;

    if (!tacAllowed) {
        return (<div className="footer_nl-form" dangerouslySetInnerHTML={{ __html: renderTacEngagement() }} />)
    }
    else {
        return <div className="footer_nl-form">
            <iframe src={newsletter.iframe.url} width="100%" height={height} frameBorder="0"
                title="Newsletter subscription footer"></iframe>
        </div>
    }
}