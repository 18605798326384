// GLOBAL STATE
export function updateGlobalStoreGlobal(datas){
	return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas){
	return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStorePage(datas){
	return {type: GLOBAL_UPDATE_PAGE, value: datas}
}

export function updateGlobalStorePageTitle(datas){
	return {type: GLOBAL_UPDATE_PAGE_TITLE, value: datas}
}

export function updateGlobalStoreLang(datas){
	return {type: GLOBAL_UPDATE_LANG, value: datas}
}

export function updateGlobalisLoaded(bool){
	return {type: GLOBAL_IS_LOADED, value: bool}
}

// SCROLL STATE
export function updateGlobalScrollLock(bool){
	return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}

// Breakpoints
export function updateIsMobile(bool){
	return {type: IS_MOBILE, value: bool}
}
export function updateIsTablet(bool){
	return {type: IS_TABLET, value: bool}
}
export function updateIsTabletWide(bool){
	return {type: IS_TABLET_WIDE, value: bool}
}
export function updateIsDesktop(bool){
	return {type: IS_DESKTOP, value: bool}
}

// ELOQUA
export function updateELOQUA(datas){
	return {type: ELOQUA, value: datas}
}

// OFFERS
export function updateOffers(datas){
	return {type: OFFERS, value: datas}
}

// OFFERS
export function updatePodcast(datas){
	return {type: PODCAST, value: datas}
}

//OLARK
export function updateGlobalIdOlark(bool){
	return {type: GLOBAL_IS_IDOLARK, value: bool}
}

export const ELOQUA  = 'ELOQUA';
export const GTM  = 'GTM';
export const GLOBAL_UPDATE_GLOBAL  = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES  = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_PAGE  = 'UPDATE_PAGE';
export const GLOBAL_UPDATE_PAGE_TITLE  = 'UPDATE_PAGE_TITLE';
export const GLOBAL_UPDATE_LANG  = 'UPDATE_LANG';
export const GLOBAL_IS_LOADED  = 'IS_LOADED';
export const GLOBAL_IS_SCROLLLOCKED  = 'IS_SCROLLLOCKED';
export const IS_MOBILE  = 'IS_MOBILE';
export const IS_TABLET  = 'IS_TABLET';
export const IS_TABLET_WIDE  = 'IS_TABLET_WIDE';
export const IS_DESKTOP  = 'IS_DESKTOP';
export const OFFERS  = 'OFFERS';
export const PODCAST  = 'PODCAST';
export const GLOBAL_IS_IDOLARK  = 'GLOBAL_IS_IDOLARK';
